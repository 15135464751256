import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import BlogPostItem from '../components/blog-post-item/blog-post-item';
import BlogIntro from '../components/blog-intro/blog-intro';
import { IPostNode } from '../common/post';

interface IBlogPageQueryData {
  allMarkdownRemark: {
    edges: {
      node: IPostNode;
    }[];
  };
}

type BlogProps = {
  data: IBlogPageQueryData;
};

const Blog: React.FC<BlogProps> = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <SEO title="Posts" />
      <section>
        <BlogIntro />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return <BlogPostItem key={node.fields.slug} post={node} title={title} />;
        })}
      </section>
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
