import React from 'react';
import { navigate } from 'gatsby';
import { IPostNode } from '../../common/post';
import * as styles from './blog-post-item.module.scss';

type BlogProps = {
  title: string;
  post: IPostNode;
};

const BlogPostItem: React.FC<BlogProps> = ({ post, title }) => {
  const blogPostLink = `/blog${post.fields.slug}`;

  const handlePostClick = () => navigate(blogPostLink);

  return (
    <div role="button" className={styles.blogPostItem} onClick={handlePostClick} tabIndex={0}>
      <h2 className={styles.title}>{title}</h2>
      <small>{post.frontmatter.date}</small>
      <p
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: post.frontmatter.description || post.excerpt
        }}
      />
    </div>
  );
};

export default BlogPostItem;
