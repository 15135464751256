import React from 'react';
import * as styles from './blog-intro.module.scss';
import Button from '../button/button';
import { navigate } from 'gatsby';
import ThemeSwitch from '../theme-switch/theme-switch';
import HeroImage from '../hero-image/hero-image';

const BlogIntro: React.FC = () => {
  const heroSize = 8;
  const handleAboutClick = () => navigate('/');
  return (
    <div className={styles.blogIntro}>
      <div className={styles.heroImage}>
        <ThemeSwitch />
        <HeroImage size={heroSize} />
      </div>
      <div className={styles.introText}>
        <h1>Welcome on my personal blog!</h1>
        <h1 className={styles.subIntro}>
          I am a <span className={styles.highlight}>Frontend web developer</span> and I write about my personal
          experiences, share technical solutions.
        </h1>
        <Button caption="About me" onClick={handleAboutClick} />
      </div>
    </div>
  );
};

export default BlogIntro;
